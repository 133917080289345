<template>
  <div id="modalUpdate">
    <div class="modal-dialogs" :class="{ 'modal-close-dialogs': !modalShow }" @click.self="closeModal()">
      <div class="modal-content-custom" :class="{ 'modal-close': !modalShow }">
        <div class="modal-body-custom">

          <span @click="closeModal()" class="close-icon-modal text-right" style="
              z-index: 99999;
              display: block;
              position: absolute;
              right: 7px;
              top: 7px;
              cursor: pointer;
            ">
            <i class="fa fa-times-circle" style="font-size: 23px"></i>
            <!-- <mdb-icon class="icon" icon="times-circle" /> -->
          </span>
          <h5 class="fw-bolder text-start">Editar Banner</h5>
          <div class="w-100 text-center drag-drop-content" :class="[
              !url ? 'drag-drop-content_show' : 'drag-drop-content_hidden',
            ]" style="position: relative; overflow: clip">
            <div>
              <div class="ratio img-preview" style="--bs-aspect-ratio: 45%;margin:auto" v-show="url && showVideo">
                <iframe :src="preview" title="YouTube video" allowfullscreen></iframe>
              </div>
              <img v-show="url && !showVideo" v-if="url" class="img-fluid img-preview" :src="preview" alt="" />
              <img class="upload-img" v-if="!url"
                src="https://kiritek-web-documents.s3.us-west-2.amazonaws.com/tres-marias/img/adminBanners/nube.png"
                alt="" />
              <br v-if="!showVideo" />
              <label for="image">{{
                url ? url : "Da clic o arrastra tu imagen aquí"
              }}</label>
            </div>
            <input type="file" accept="image/png, image/jpeg, image/jpg, video/mp4" placeholder="url" id="url"
              v-on:change="getFile" name="image" />
          </div>
          <div v-if="errorFile" class="text-start" style="margin-top: 5px">
            <span style="font-size: 14px; font-weight: 500; color: #9c251c">*Debe seleccionar un archivo</span>
          </div>
          <div v-if="nameFile" class="text-start" style="margin-top: 5px">
            <span style="font-size: 14px; font-weight: 500; color: #9c251c">*El nombre {{showVideo?'del video':'de la imagen' }} <b>no</b> debe contener
              caracteres especiales, espacios y debe tener un máximo de 30 caracteres</span>
          </div>
          <div v-if="sizeFile" class="text-start" style="margin-top: 5px">
            <span style="font-size: 14px; font-weight: 500; color: #9c251c">*El peso {{showVideo?'del video':'de la imagen' }} 
              <b>no</b> debe exceder de <b>{{showVideo?'5mb':'1mb' }}</b>.</span>
          </div>
          <div class="text-start">
            <h6 class="fw-semibold mt-3">Selecciona el micrositio:</h6>
            <select id="selectUpdateMicrosite" class="w-100">
              <option :value="option.value" v-for="(option, index) in optionsInput" :key="index"
                :selected="option.selected">
                {{ option.name }}
              </option>
            </select>
          </div>
          <hr />
          <div>
            <button class="btn-cancel me-1" @click="closeModal()">
              Cancelar</button><button class="btn-accept ms-1" @click="sendBanner()">
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "ModalUpdate",
  props: ["modalUpdate", "dataBanner"],
  data() {
    return {
      //data to update
      id: null,
      file: null,
      url: "",
      microsite: "",
      linkMicrosite: null,
      dateUrl: null,
      //other
      preview: null,
      image: null,
      //validations
      errorFile: false,
      nameFile: false,
      sizeFile:false,
      lengthTitle: false,
      options: [
        {
          name: "No Aplica",
          value: "no aplica",
          selected: true,
          link: "no aplica",
        },
        {
          name: "Alamos",
          value: "alamos",
          selected: false,
          link: "/alamos/terrenos",
        },
        {
          name: "Bosques",
          value: "bosques",
          selected: false,
          link: "/bosques/casas",
        },
        {
          name: "Cañadas del Bosque",
          value: "canadas",
          selected: false,
          link: "/canadas/casas",
        },
        {
          name: "Ciudad Salud",
          value: "ciudad salud",
          selected: false,
          link: "/ciudadsalud/zonacomercial",
        },
        {
          name: "Club de Golf",
          value: "club de golf",
          selected: false,
          link: "/clubdegolf/terrenos",
        },
        {
          name: "Lindavista",
          value: "lindavista",
          selected: false,
          link: "/lindavista/terrenos",
        },
        {
          name: "LomAlta",
          value: "lomalta",
          selected: false,
          link: "/lomalta/terrenos",
        },
        {
          name: "Paseo del Parque",
          value: "paseo del parque",
          selected: false,
          link: "/paseodelparque/terrenos",
        },
        {
          name: "Prado Sur",
          value: "prado sur",
          selected: false,
          link: "/pradosur/terrenos",
        },
        {
          name: "Senderos",
          value: "senderos",
          selected: false,
          link: "/senderos/terrenos",
        },
        {
          name: "Torre Airea Plus",
          value: "torre airea",
          selected: false,
          link: "/torreairea/departamentos",
        },
        {
          name: "Torres Paseo del Parque",
          value: "torres paseo del parque",
          selected: false,
          link: "/torrespaseodelparque/departamentos",
        },
        {
          name: "Valle Norte",
          value: "valle norte",
          selected: false,
          link: "/vallenorte/terrenos",
        },
        {
          name: "Valle Sur",
          value: "valle sur",
          selected: false,
          link: "/vallesur/terrenos",
        },
      ],
    };
  },
  computed: {
    modalShow() {
      return this.modalUpdate;
    },
    banner() {
      return this.dataBanner;
    },
    optionsInput() {
      return this.options;
    },
    showVideo() {
      if (this.url) {
        return this.url.includes('.mp4')
      } else {
        return false
      }
    },
  },
  watch: {
    modalShow(newVal) {
      if (newVal) {
        this.activeSelected();
      } else {
        this.desactivSeleted();
        this.errorFile = false;
      }
    },
  },
  methods: {
    ...mapActions(["getBanners", "uploadBanner", "uploadImg"]),
    closeModal() {
      this.$emit("modalClose");
      setTimeout(() => {
        this.resetAllElements();
      }, 700);
    },
    getSelectValue() {
      let selectData = document.getElementById("selectUpdateMicrosite");
      this.microsite = selectData.value;
    },
    compareMicrosite() {
      this.getSelectValue();
      for (let index = 0; index < this.options.length; index++) {
        if (this.options[index].value === this.microsite) {
          this.linkMicrosite = this.options[index].link;
        }
      }
    },
    getDateTime() {
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let newMonth = Number(month) + 1
      if (day < 10) {
        /*  console.log("un digito") */
        day = `0${day}`;
      }
      if (month < 10) {
        if(newMonth<10){
          newMonth = `0${newMonth}`;
        }
        /* console.log("un digito") */
      }
      if (hour < 10) {
        hour = `0${hour}`;
        /* console.log("un digito") */
      }
      if (minute < 10) {
        minute = `0${minute}`;
        /* console.log("un digito") */
      }

      let datetime = `${day}-${newMonth}-${year}-${hour}:${minute}`;
      this.dateUrl = datetime;
    },
    getFile(ev) {
      let files = ev.target.files;
      this.file = files;
      this.url = this.file[0].name;
      var input = ev.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
      let validate = /^[a-zA-Z0-9._-]+$/
      if (this.file) {
        this.errorFile = false;
        if (this.url.length > 34 || !validate.test(this.url)) {
          this.nameFile = true;
        } else {
          this.nameFile = false;
        }
        if(this.showVideo){
          if(this.file[0].size>=5000000){
              this.sizeFile=true
          }else{
            this.sizeFile=false;
          }
        }else{
          if(this.file[0].size>=1000000){
              this.sizeFile=true
          }else{
            this.sizeFile=false;
          }
        }
      } else {
        this.errorFile = true;
      }
    },
    reset() {
      this.image = null;
      this.preview = null;
    },
    resetAllElements() {
      this.id = null;
      this.file = null;
      this.url = null;
      this.image = null;
      this.preview = null;
      this.microsite = null;
      this.linkMicrosite = null;
      this.nameFile=false;
      this.sizeFile=false;

    },
    activeSelected() {
      for (let index = 0; index < this.options.length; index++) {
        if (this.options[index].value === this.banner.microsite) {
          this.options[index].selected = true;
        }
      }
    },
    desactivSeleted() {
      for (let index = 0; index < this.options.length; index++) {
        this.options[index].selected = false;
      }
    },
    async sendBanner() {
      if (this.file) {
        if (!this.nameFile) {
          this.getSelectValue();
          this.compareMicrosite();
          this.getDateTime();
          let src = `${this.dateUrl}_${this.url}`;
          let orderBanner = this.banner.order;
          let id = this.banner._id;
          console.log(src + "bbb");
          let params = {
            id: id,
            body: {
              url: src,
              microsite: this.microsite,
              link: this.linkMicrosite,
              order: orderBanner,
            },
          };
          console.log(params)
          try {
            await this.uploadBanner(params);
            await this.uploadImg(this.file);
            console.log("uno");
            /*  */
            console.log("dos");
            setTimeout(() => {
              this.getBanners();
              console.log("tres");
              this.file = null;
              this.closeModal();
            }, 2000);
          } catch (error) {
            console.log(error);
          }
        } else {
          this.nameError = true
        }
      } else {
        this.errorFile = true;
      }
    },
  },
};
</script>
<style scoped>
.modal-dialogs {
  background: hsla(0, 0%, 100%, 0.5) !important;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: visible;
  opacity: 1;
  transition: 0.7s all ease-out;
}

.modal-content-custom {
  max-width: 700px;
  width: 90%;
  opacity: 1;
  transition: 0.7s all ease-out;
  transform: translateY(0px);
  background: white;
  box-shadow: 0px 0px 14px 0px rgba(122, 122, 122, 0.75);
  -webkit-box-shadow: 0px 0px 14px 0px rgba(122, 122, 122, 0.75);
  -moz-box-shadow: 0px 0px 14px 0px rgba(122, 122, 122, 0.75);
  border-radius: 10px;
}

.modal-body-custom {
  position: relative;
  padding: 1rem;
}

.modal-dialogs.modal-close-dialogs {
  visibility: hidden;
  opacity: 0;
  transition: 0.7s all ease-out;
}

.modal-content-custom.modal-close {
  opacity: 0;
  transform: translateY(-50px);
  transition: 0.7s all ease-out;
}

.close-icon-modal {
  text-align: right;
  z-index: 99999;
}

.close-icon-modal .icon {
  font-size: 20px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
}

input {
  position: absolute;
  left: 0px;
  top: 0px;
  min-height: 145px;
  width: 100%;
  opacity: 0;
}

.drag-drop-content_show {
  min-height: 130px;
  padding: 20px;
  border: 2px dashed #07163b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-preview {
  max-width: 460px;
}

.upload-img {
  max-width: 80px;
  opacity: 0.8 !important;
}

h5 {
  color: #07163b;
  font-size: 18px;
}

.btn-cancel {
  border: 2px solid gray;
  color: gray;
  border-radius: 5px;
  padding: 2px 7px;
  font-size: 16px;
  background: white;
}

.btn-accept {
  border: 2px solid #07163b;
  color: white;
  background: #07163b;
  border-radius: 3px;
  padding: 2px 7px;
  font-size: 16px;
}
</style>

