<template>
    <div class="container-fluid foot__admon py-4">
        <div class="row">
            <div class="col-12 text-center">
                &copy; Desarrollado por <a href="https://kiritek.com/" target="_blank">Kiritek</a>
            </div>
        </div>
        
    </div>
</template>
<script>
export default {
    name: 'Foot'
}
</script>
<style scoped>
.foot__admon {
    font-size: 13px;
    color: hsla(0, 0%, 100%, .6);
    background: #07163b;
}

.foot__admon a {
    text-decoration: none;
    color: white;
}
</style>