import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";

let API_URL =
  /* 'https://dev-api-web.tresmarias.com.mx' */ process.env.VUE_APP_API_URL;

Vue.use(Vuex);
/* console.log("hola")
console.log(process.env.VUE_APP_API_URL) */

export default new Vuex.Store({
  state: {
    banners: [],
    bannersCompare: [],
    videoCrucero: "",
    imgsPreview: [],
    terms: [],
  },
  getters: {
    getStateBanners: (state) => {
      return state.banners;
    },
    getStateBannersCompare: (state) => {
      return state.bannersCompare;
    },
    getStateVideoCrucero: (state) => {
      return state.videoCrucero;
    },
    getStateImgsPreview: (state) => {
      return state.imgsPreview;
    },
    getStateTerms: (state) => {
      return state.terms;
    },
  },
  mutations: {
    _setBanners(state, banner) {
      state.banners = banner;
      let imgs = [];
      let bucket =
        "https://kiritek-web-documents.s3.us-west-2.amazonaws.com/tres-marias/assets/images/main-banner/";
      state.banners.forEach((element) => {
        imgs.push(`${bucket}${element.url}`);
      });
      state.imgsPreview = imgs;
    },
    _setBannersCompare(state, banner) {
      state.bannersCompare = banner;
    },
    _setVideoCrucero(state, video) {
      state.videoCrucero = video;
    },
    _setTerms(state, terms) {
      state.terms = terms;
    },
  },
  actions: {
    async getBanners(context) {
      try {
        let response = await Axios.get(`${API_URL}/banners`);
        context.commit("_setBanners", response.data);
      } catch (e) {
        console.log(e);
      }
    },
    async getBannersCompare(context) {
      try {
        let response = await Axios.get(`${API_URL}/banners`);
        context.commit("_setBannersCompare", response.data);
      } catch (e) {
        console.log(e);
      }
    },
    async addBanner(context, data) {
      await Axios.post(`${API_URL}/banners`, data);
    },
    async uploadBanner(context, data) {
      await Axios.put(`${API_URL}/banners/banner/${data.id}`, data.body);
    },
    async deleteBanner(context, id) {
      await Axios.delete(`${API_URL}/banners/banner/${id}`);
    },
    async uploadImg(context, file) {
      file = file[0];

      await Axios.post(
        `${API_URL}/banners/upload`,
        {
          files: file,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        },
      );
    },
    async getVideoCrucero(context) {
      try {
        let response = await Axios.get(`${API_URL}/video`);
        context.commit("_setVideoCrucero", response.data);
      } catch (e) {
        console.log(e);
        console.log("error");
      }
    },
    async uploadVideoCrucero(context, data) {
      await Axios.put(`${API_URL}/video/${data.id}`, data.body);
    },
    async getTerms(context) {
      try {
        let response = await Axios.get(`${API_URL}/terms`);
        context.commit("_setTerms", response.data);
      } catch (e) {
        console.log(e);
        console.log("error");
      }
    },
    async addTerms(context, data) {
      await Axios.post(`${API_URL}/terms`, data);
    },
    async uploadTerms(context, data) {
      await Axios.put(`${API_URL}/terms/term/${data.id}`, data.body);
    },
    async deleteTerms(context, id) {
      await Axios.delete(`${API_URL}/terms/term/${id}`);
    },
    async uploadTermPdf(context, file) {
      file = file[0];

      await Axios.post(
        `${API_URL}/terms/upload`,
        {
          files: file,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        },
      );
    },
    /* async (context, file) {
      file = file[0]
      
      await Axios.post(`${API_URL}`, {
        files: file
      }, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/form-data'
        },
      });
    }, */
  },
  modules: {},
});
