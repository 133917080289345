<template>
  <div class="row">
    <div class="col-12 py-3"  content="Presiona y arrastra los elementos para cambiar el orden" 
    v-tippy="{arrow : true, arrowType : 'round', animation : 'scale'}">
      <h5>Editar orden</h5>
      <draggable
        v-model="bannersArr"
       
        group="people"
        @start="drag = true"
        @end="drag = false"
        class="dragg"
      >
        <div class="py-2 px-3 row__banner-editorder" v-for="(banner, index) in bannersArr" :key="index">
          <span class="fw-bolder">{{ index + 1 }}</span>
          <iframe style="max-width: 100px;max-height: 40px;margin: 0 3rem" v-if="banner.url.includes('.mp4')" :src="`${bucket}${banner.url}`" title="YouTube video" allowfullscreen></iframe>
          <img
            v-if="!banner.url.includes('.mp4')"
            :src="`${bucket}${banner.url}`"
            style="max-width: 100px; margin: 0 3rem"
          />
          <span>{{ banner.microsite }}</span>
        </div>
      </draggable>
      
    </div>
    <div class="pt-3 content__button text-end">
      <h6 class="mt-3">Presiona y arrastra los elementos para cambiar el orden.</h6>
      <button v-if="order" @click="cancelOrder()" class="btn btn-order me-1">
        Cancelar
      </button>
      <button v-if="order" @click="saveOrder()" class="btn btn-admin ms-1">
        Guardar orden
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
export default {
  name: "EditOrder",
  props: ["orderBtn"],
  components: {
    draggable,
  },
  computed: {
    ...mapGetters({
      banners: "getStateBanners",
      original: "getStateBannersCompare",
    }),
    order() {
      return this.orderBtn;
    },
  },
  data() {
    return {
      bannersArr: null,
      bucket:
        "https://kiritek-web-documents.s3.us-west-2.amazonaws.com/tres-marias/assets/images/main-banner/",
    };
  },
  methods: {
    ...mapActions(["getBanners", "getBannersCompare", "uploadBanner"]),
    async saveOrder() {
      /* console.log(this.original)
       console.log(this.bannersArr) */
      for (let index = 0; index < this.bannersArr.length; index++) {
        const element = this.bannersArr[index];
        const data = {
          id: element._id,
          body: {
            link: element.link,
            microsite: element.microsite,
            order: index + 1,
            url: element.url,
          },
        };
        try {
          await this.uploadBanner(data);
        } catch (error) {
          console.log(error);
        }
      }
      setTimeout(() => {
        this.getBanners();
      }, 700);
      setTimeout(() => {
        this.cancelOrder();
      }, 1700);
    },
    cancelOrder() {
      this.$emit("cancelOrder");
    },
  },
  async created() {
    await this.getBanners();
    await this.getBannersCompare();
    this.bannersArr = this.banners;
  },
};
</script>
<style>
.tippy-tooltip{
    font-size: 13px;
}
.row__banner-editorder{
  cursor:grab;
}
.row__banner-editorder.sortable-chosen{
  cursor:grabbing;
}
</style>
<style scoped>
.dragg div {
  border-bottom: 1px solid silver;
  border-top: none;
}
/* .dragg div:first-child{
    border-bottom: 1px solid gray;
    border-top: none;
} */
.dragg div:last-child {
  border-top: none;
  border-bottom: none;
}
.btn-admin {
  background: #07163b;
  color: white;
  border: none;
  border: 2px solid #07163b;
}
.btn-order {
  background: white;
  color: #07163b;
  border: 2px solid #07163b;
}
.content__button {
  background: white;
}
h5{
  font-weight: 700;
  padding:5px 10px;
  font-size: 20px;
}
h6{
  font-size: 15px;
  padding:0px 10px;
  font-weight: 600;
  display: inline-block;
}
</style>
