<template>
  <div class="terms-items">
    <div class="content-items py-3 pb-4" v-if="!order">
      <div class="row container-fluid">
        <div class="col-lg-7 col-md-7 col-sm-7 col-6">
          <h5 class="pb-2">Elementos</h5>
        </div>
        <div class="col-lg-5 col-md-5 col-sm-5 col-6">
          <PreviewTerms v-if="modeDevelop" />
        </div>
      </div>
      <div class="container-fluid px-4" v-if="terms.length > 0">
        <div class="row pb-1">
          <div class="col-lg-1 col-md-1 col-sm-2 col-2">
            <b>Orden</b>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col">
            <b>Documento</b>
          </div>
          <div></div>
        </div>
        <ItemTerms
          class="border-bottom-items py-2"
          :class="{ 'border-top-items': index == 0 }"
          v-for="(item, index) in terms"
          :key="index"
          :idItem="index"
          :textItem="item"
          :order="(item.order = index + 1)"
        />
        <div class="row pt-3" v-if="terms.length >= 4">
          <div class="col-12 text-center msg-terms">
            <b
              >Límite de términos alcanzados, sólo puedes agregar hasta 4
              términos.</b
            >
          </div>
        </div>
      </div>
      <msj-default v-else></msj-default>
    </div>
    <div class="content-items" v-if="order">
      <Order :orderBtn="order" @cancelOrder="hideOrder" />
    </div>
    <!-- <div v-html="cont8ent"></div> -->
    <div style="font-size: 15px; text-align: justify"></div>

    <div class="text-end py-3 container-fluid content-btn">
      <button v-if="!order" @click="order = !order" class="btn btn-order me-1">
        Cambiar orden
      </button>
      <AddItem v-if="!order" />
      <!-- <button class="btn btn-admin ms-1" @click="openAdd()">Agregar bloque</button> -->
    </div>
    <!-- <vue-editor class="mb-3" v-model="content" :editor-toolbar="customToolbar" />
        <div>{{content}}</div> -->
    <!-- <div v-html="content"></div> -->
  </div>
</template>
<script>
import ItemTerms from "./blocks/ItemTerms";
import AddItem from "./blocks/AddTerm.vue";
import Order from "./Order.vue";
import MsjDefault from "../MsjDefault.vue";
import PreviewTerms from "./preview/PreviewTerms.vue";
import { mapActions, mapGetters } from "vuex";

// import { VueEditor } from "vue2-editor";
export default {
  name: "EditTerms",
  components: {
    //VueEditor,
    ItemTerms,
    AddItem,
    Order,
    MsjDefault,
    PreviewTerms,
  },
  computed: {
    ...mapGetters({
      terms: "getStateTerms",
    }),
    modeDevelop() {
      if (process.env.NODE_ENV === "development") {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      order: false,
      content: ``,
      customToolbar: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    };
  },
  methods: {
    ...mapActions(["getTerms"]),
    hideOrder() {
      this.order = false;
    },
  },
  async created() {
    await this.getTerms();
  },
};
</script>
<style>
.ql-editor {
  max-height: 200px;
  font-size: 16px !important;
}

.ql-picker-label {
  display: flex !important;
}

.ql-align-justify {
  text-align: justify;
}

.ql-align-center {
  text-align: center;
}

.error-input {
  font-size: 14px;
  font-weight: 500;
  color: rgb(156, 37, 28);
}
</style>
<style scoped>
.terms-items {
  background: #f0f0f0;
}

.border-bottom-items {
  border-bottom: 1px solid silver;
}

.border-top-items {
  border-top: 1px solid silver;
}

.content-btn {
  background: white;
}

h5 {
  font-weight: 700;
  padding: 5px 10px;
  font-size: 20px;
}

.btn-order {
  background: white;
  color: #07163b;
  border: 2px solid #07163b;
}

@media (max-width: 500.67px) {
  .content__button button {
    font-size: 13.5px;
  }
  .msg-terms {
    font-size: 14px;
  }
  .btn-order {
    font-size: 13.5px !important;
  }
}
</style>
