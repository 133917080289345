<template>
  <div class="container-fluid content__instructions py-4 px-5">
    <div class="row">
      <div class="col-12">
        <div class="col-12 mb-3 text-center pt-3 pb-3">
          <h1>TÉRMINOS Y CONDICIONES</h1>
        </div>
        <ul>
          <li>Solo se permiten archivos con formato <b>PDF.</b></li>
          <li>El peso de los archivos no deberá exceder de <b>5mb.</b></li>
          <li>
            El nombre del archivo <b>no debe contener espacios (utilizar _ o - en
            su lugar) ni caracteres especiales</b>.
          </li>
          <li>
            Solo se pueden agregar <b>hasta 4 términos</b>.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Title",
};
</script>
<style scoped>
h4.title {
  font-weight: 700;
  text-transform: uppercase;
}
.content__instructions {
  background: white;
}
h1 {
  font-size: 24px;
  font-weight: 700;
}

@media (max-width:700px) {
    h1{
        font-size: 20px;
    }
    h4{
      font-size: 17px;
    }
    li{
      font-size: 14px;
    }
}


</style>