<template>
  <div class="delete-term">
    <button class="btn__admon btn-delete me-1 btn-web" @click="openDelete()">
      Eliminar
    </button>
    <!-- <span class="btn-mobile" @click="openDelete()">Eliminar</span> -->
    <Delete
      msgDelete="¿Seguro que deseas eliminar este bloque?"
      @closeDeleteModal="closeDelete()"
      :modal="modalDeleteOpen"
      @deleteElement="deleteTerm()"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Delete from "../../../modals/Delete.vue";
export default {
  name: "DeleteTerm",
  props: ["contentItem", "modalMobile"],
  components: {
    Delete,
  },
  computed: {
    ...mapGetters({
      terms: "getStateTerms",
    }),
    modalDeleteOpen() {
      if (this.modalMobile) {
        return this.modalMobile;
      } else {
        return this.modalDelete;
      }
    },
    id() {
      return this.contentItem._id;
    },
  },
  data() {
    return {
      modalDelete: false,
    };
  },
  methods: {
    ...mapActions(["getTerms", "deleteTerms"]),
    openDelete() {
      this.modalDelete = true;
    },
    closeDelete() {
      this.modalDelete = false;
      if (this.modalMobile) {
        this.$emit("closeModalDelete");
      }
    },
    async deleteTerm() {
      let id = this.id;
      try {
        await this.deleteTerms(id);
        setTimeout(() => {
          this.getTerms();
          this.closeDelete();
        }, 500);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style style>
.delete-term {
  display: inline-block;
}

.btn__admon {
  padding: 3px 7px;
  background: transparent;
}

.btn-delete {
  border: 2px solid #9c251c;
  border-radius: 3px;
  color: #9c251c;
}
</style>
<style scoped>
@media (min-width: 891px) {
  .btn-mobile {
    display: none;
  }
}
@media (max-width: 870px) {
  .btn-web {
    display: none;
  }
}
@media (max-width: 500.67px) {
  button {
    font-size: 13.5px !important;
  }
}
</style>
