import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueYoutube from 'vue-youtube'
import VueTippy, { TippyComponent } from "vue-tippy";
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";
/* Amplify imports*/
import { Amplify} from 'aws-amplify';
import awsconfig from './aws-exports';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';
/* imports lang amplify  */
import { I18n } from "aws-amplify"
import AmplifyI18n from "amplify-i18n"

/* configure amplify */
applyPolyfills().then(() => {
  defineCustomElements(window);
});

Amplify.configure(awsconfig);
  
Vue.config.ignoredElements = [/amplify-\w*/];
Vue.use(Amplify)
/* configure lang amplify */

AmplifyI18n.configure()
I18n.setLanguage("es")

Vue.config.productionTip = false

Vue.use(VueYoutube)
Vue.use(VueTippy);

Vue.component("tippy", TippyComponent);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
