<template>
    <div class="col-btn__preview">
        <button class="btn-preview ms-2" @click="openModal()">Vista previa</button>
        <Modal titleModal="Vista previa" :modal="modalShow" :oneButton="true" @acceptAddModal="closeModal"
            @closeAddModal="closeModal" :previewSize="true">
            <p>Este es solamente una vista previa del footer de la página web</p>
            <div class="container_preview container-fluid pt-4">
                <div class="row fst-row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div class="content-simule-logo">
                            <div class="content-logo">
                                <div class="simule-logo"></div>
                            </div>
                            <div class="content-socials web">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-simule-text">
                        <div class="content-text-simule d-flex">
                            <div class="col-simule-left">
                                <h5 class="simule-title"></h5>
                                <p class="simule-text pt-1" style="width: 80%;"></p>
                                <p class="simule-text" style="width: 90%;margin-bottom:4px"></p>
                                <p class="simule-text" style="width: 70%;"></p>
                                <p class="simule-text" style="width: 50%;"></p>

                            </div>
                            <div class="col-simule-right">
                                <h5 class="simule-title"></h5>
                                <p class="simule-text" style="width: 60%;margin-bottom:4px"></p>
                                <p class="simule-text" style="margin-bottom:4px"></p>
                                <p class="simule-text" style="width: 70%;"></p>
                                <p class="simule-text" style="margin-bottom:4px"></p>
                                <p class="simule-text" style="width: 70%;"></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mobile">
                    <div class="content-socials">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div class="row scnd-row pb-2">
                    <div class="col-md-6 col-sm-12 col-terms">
                        <hr class="line-mobile line">
                        <div class="row">
                            <div class="col-md-12" v-for="(item, index) in terms " :key="index">
                                <span class="terms-link">{{ item.title }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 col-copy">
                        <hr class="line-web line web">
                        <div class="row row-copy">
                            <div>
                                <p class="simule-text" style="width: 70%;"></p>
                            </div>
                            <div>
                                <p class="simule-text" style="width: 70%;"></p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </Modal>
    </div>
</template>
<script>
import Modal from "../../../modals/Add.vue"
import { mapGetters } from "vuex";
export default {
    name: "PreviewTerms",
    components: {
        Modal
    },
    data() {
        return {
            modal: false
        }
    },
    computed: {
        modalShow() {
            return this.modal
        },
        ...mapGetters({
        terms: 'getStateTerms'
        }),
    },
    methods: {
        openModal() {
            this.modal = true
        },
        closeModal() {
            this.modal = false
        }
    }

}
</script>
<style>
.btn-preview {
    border: 2px solid black;
    border-radius: 3px;
    color: #f0f0f0;
    background: black;
    padding: 3px 7px;
}

.container_preview {
    background: #07163b;
    color: white;
}

.simule-logo {
    width: 200px;
    background: silver;
    height: 45px;
    margin-bottom: 2rem;
}

.content-socials {
    display: flex;
}

.content-socials>div {
    width: 36px;
    height: 36px;
    background: silver;
    border-radius: 50px;
    margin-left: 10px;
}

.content-text-simule>div {
    width: 50%;
    display: inline-block;
}

.simule-title {
    width: 70%;
    background: silver;
    height: 23px;
}

.simule-text {
    /* width: 75%; */
    background: silver;
    height: 19.5px;
}

.scnd-row>div {
    padding-left: 3%;
    padding-right: 3%;
}

.row-copy>div {
    width: 50%;
    display: inline-block;
    padding: 0;
    padding: 0 24px;
}
.line{
    margin-top: 0;
}
.col-terms{
    text-align: center;
}
.terms-link{
    cursor: pointer;
    color: #fff;
    text-decoration: underline;
    font-size: 13px;
}

@media(min-width:935px) {
    .fst-row>div {
        padding-left: 6%;
        padding-right: 6%;
    }
}

@media (max-width:870.67px) {
    .col-btn__preview {
        text-align: right;
    }
}
@media (min-width:768px) {
    .mobile{
        display: none;
    }
}

@media (max-width:767.67px) {
    .web{
        display: none;
    }
    .simule-logo {
        margin: auto;
        margin-bottom: 2rem;
    }
    .simule-title{
        margin: auto;
        margin-bottom: .5rem;
    }
    .simule-text{
        margin: auto;
        margin-bottom: 1rem;
    }
    .mobile .content-socials{
        max-width: 620px;
        display: block;
        text-align: center;
        margin-bottom: 1rem;
    }
    .mobile .content-socials>div{
        display: inline-block;
    }
}
@media (max-width:500.67px) {
    .btn-preview{
        font-size: 13.5px;
    }
}
</style>