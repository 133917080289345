<template>
  <div class="w-100">
    <div class="w-100 text-end">
      <button type="button" class=" dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" id="dropdownAdmon">
        <i style="font-size:15px" class="fa fa-ellipsis-v"></i>
      </button>
      <ul class="dropdown-menu">
        <li><a class="dropdown-item txt-document" :href="`${URL}${content.file}`" target="_blank">
            Ver documento
          </a></li>
        <li><a class="dropdown-item txt-delete" href="#" @click.prevent="openModalDelete()">
            Eliminar
          </a></li>
        <li><a class="dropdown-item txt-edit" href="#" @click.prevent="openModalEdit()">
            Editar
          </a></li>
      </ul>
    </div>
    <DeleteTerm :contentItem="content" :modalMobile="modalDelete" @closeModalDelete="closeDelete" />
    <Edit :modalMobile="modalEdit" :contentItem="content" @closeModalEdit="closeEdit" />
  </div>
</template>
<script>
import DeleteTerm from "./DeleteTerm.vue"
import Edit from "./EditTerm.vue"
export default {
  name: 'TermsOptions',
  props: ['contentItem'],
  components: {
    DeleteTerm,
    Edit
  },
  data() {
    return {
      modalEdi: false,
      modalDel: false,
      URL: 'https://kiritek-web-documents.s3.us-west-2.amazonaws.com/tres-marias/assets/images/main-banner/terms/'
    }
  },
  computed: {
    content() {
      return this.contentItem
    },
    modalEdit() {
      return this.modalEdi
    },
    modalDelete() {
      return this.modalDel
    }
  },
  methods: {
    openModalEdit() {
      this.modalEdi = true
    },
    closeEdit() {
      this.modalEdi = false
    },
    openModalDelete(){
      this.modalDel=true
    },
    closeDelete(){
      this.modalDel=false
    }
  }

}
</script>
<style>
.txt-delete {
  color: #9c251c;
  font-weight: 500;
  font-size: 15px;
}

.txt-delete:active {
  color: #9c251c;
}

.txt-edit {
  font-weight: 500;
  font-size: 15px;
}

.txt-edit:active {
  background: rgb(233, 236, 239);
  color: black
}

.txt-delete:hover {
  background: #9c251c2b;
}
.txt-document{
  /* background:  */
  color: #07163b;
  font-weight: 500;
  font-size: 15px
}
</style>
