import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from '../components/admin/Main.vue'
import Login from '../components/login/Auth.vue'

import Video from '../components/admin/crucero/EditVideo'
import Admon from '../components/admin/sliderBanners/Main'
import Terms from '../components/admin/terms/Main'
Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    component:Login,
    meta:{title:'Iniciar sesión',auth:true}
  },
  {
    path: '/admin',
    name: 'Admon',
    component: Main,
    meta:{title:'Administrador de slider',auth:true},
    children:[
      {
        path:'/admin/admin-tm',
        name:'admin-tm',
        component:Admon,
        meta:{title:'Administrador de slider',auth:true},
      },
      {
        path:'/admin/banners',
        name:'banners',
        component:Admon,
        meta:{title:'Administrador de slider',auth:true}
      },
      {
        path:'/admin/crucero',
        name:'crucero',
        component:Video,
        meta:{title:'Administrador de video crucero',auth:true}
      },
      {
        path:'/admin/terms',
        name:'terms',
        component:Terms,
        meta:{title:'Administrador de términos y condiciones',auth:true}
      },
      {
        path:'*',
        name:'erro',
        component:Login,
      }
    ]
  },
  
  
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    
          return {x: 0, y: 0}
   
  },
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});



export default router
