<template>
  <div class="container-fluid px-1 pt-3 header__admin">
    <div class="row">
      <div class="col-12 mb-3 px-5 logo__container">
        <img src="https://kiritek-web-documents.s3.us-west-2.amazonaws.com/tres-marias/img/logo-tm-blanco.svg"
          alt="img-fluid" @click="toHome()" />
        <button @click="signOut()" class="btn-back-website">Cerrar sesión</button>
      </div>
      <!-- <div class="col-12 mb-3 text-center pt-3 pb-3">
        <h1>ADMINISTRADOR DE BANNERS DEL SLIDER</h1>
      </div> -->
      <div class="col-12 pt-4 links-admon hide-admon">
        <router-link :to="linkAdmin">Banner Sliders</router-link>
        <router-link v-if="show" to="/admin/crucero">Crucero Video</router-link>
        <router-link to="/admin/terms">Términos y condiciones</router-link>
      </div>
      <div class="links-admon-mobile  pt-4">
        <div class="dropdown">
          <button class="btn dropdown-amdon-menu  dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
            {{ showTextLinkActive }}
          </button>

          <ul class="dropdown-menu text-center" >
            <li>
              <a class="dropdown-item" v-if="getPath!=linkAdmin" href="#" @click.prevent="toUrl(linkAdmin)">Banner sliders</a>
            </li>
            <li><a class="dropdown-item" v-if="show && getPath!='/admin/crucero'" href="#" @click.prevent="toUrl('/admin/crucero')">Crucero Video</a>
            </li>
            <li><a class="dropdown-item" v-if="getPath!='/admin/terms'" href="#" @click.prevent="toUrl('/admin/terms')">Términos y condiciones</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Auth } from "aws-amplify";
export default {
  name: "Header",
  data() {
    return {
      show: true,
      authenticated: false,
      link: "",
    };
  },
  computed: {
    linkAdmin() {
      this.verifyLink()
      return this.link
      /* if (path == "/admin-tm") {
        this.show = false;
        return "/admin-tm";
      } else {
        this.show = true;
        return "/admin/banners";
      } */

    },
    getPath(){
      return this.$route.path
    },
    showTextLinkActive() {
      let path = this.$route.path;
      if (path == "/admin/admin-tm" || path == "/admin/banners") {
        return "Banner sliders"
      } else if (path == "/admin/terms") {
        return "Términos y condiciones"
      } else if (path == "/admin/crucero") {
        return "Crucero video"
      } else {
        return "Menu"
      }
    }
  },
  methods: {
    toUrl(url) {
      if (this.$route.path !== url) {
        this.$router.push(url);
      }

    },
    verifyLink() {
      let path = this.$route.path;
      if (path == "/admin/admin-tm" || path == "/admin/terms") {
        this.link = "/admin/admin-tm";
        this.show = false;
      } else if (path == '/admin' || path == '/admin/') {
        this.link = "/admin/admin-tm";
        this.show = false;
      } else {
        this.show = true;
        this.link = "/admin/banners";
      }
    },
    toHome() {
      this.$router.push("/");
    },
    async signOut() {
      try {
        await Auth.signOut();
        this.$router.push("/")
      } catch (error) {
        console.log("error signing out: ", error);
      }
    },
    redirectAdmin() {
      let path = this.$route.path
      if (path == "/admin" || path == "/admin/") {
        this.$router.push("/admin/admin-tm")
      }

    },
  },
  mounted() {
    Auth.currentAuthenticatedUser().then(() => {
      this.redirectAdmin();
      this.authenticated = true;
      /*  console.log("aaaaa"); */
    }).catch((error) => {
      console.log(error);
      this.$router.push("/")
    });
  },
};
</script>
<style>
.dropdown-amdon-menu {
  background: white !important;
  color: #07163b !important;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 0;
  padding: 10px 20px;
  border: none;
  width: 226px;
  
}

.dropdown-amdon-menu:hover {
  background: white !important;
  color: #07163b !important;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 0;
  padding: 10px 20px;
}

.dropdown-amdon-menu:active {
  background: white !important;
  color: #07163b !important;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  
  padding: 10px 20px;
}

.links-admon-mobile .dropdown-item {
  text-transform: uppercase;
  font-size: 13px;
  padding: 10px 20px!important;
}
.links-admon-mobile .dropdown-item:active{
  background: #e9ecef!important;
  color:black!important;
}
.links-admon-mobile .dropdown-menu.show{
  width: 226px!important;
  padding: 0px!important;
  border-radius: 0!important;
  border-top: 0!important;
}
</style>
<style scoped>
img {
  max-width: 200px;
}

h1 {
  font-size: 24px;
  font-weight: 700;
  color: white;
}

.header__admin {
  background: #07163b;
}

.logo__container {
  position: relative;
}

.btn-back-website {
  position: absolute;
  right: 40px;
  display: inline-block;
  text-decoration: none;
  color: white;
  border: 2px solid white;
  padding: 3px 7px;
  font-size: 13px;
  background: transparent;
}

.links-admon a {
  padding: 10px 20px;
  display: inline-block;
  margin-left: 10px;
  font-size: 15px;
  text-decoration: none;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
}

.links-admon a.router-link-exact-active.router-link-active {
  color: #07163b;
  background: white;
}

.links-admon-mobile {
  margin-left: 10px;
}

@media (max-width: 700px) {
  h1 {
    font-size: 20px;
  }

  .links-admon a {
    font-size: 13px;
  }
}

@media(min-width:601px) {
  .links-admon-mobile {
    display: none;
  }
}

@media(max-width:600.67px) {
  .links-admon.hide-admon {
    display: none;
  }
}

@media (max-width: 400px) {
  .logo__container.px-5 {
    padding-left: 1rem !important;
  }
}

@media (max-width: 350px) {
  .btn-back-website {
    right: 25px;
  }
}
</style>