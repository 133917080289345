<template>
  <div id="modalUpdate">
      <!--  {{hola}} -->
      <div class="modal-dialogs" :class="{ 'modal-close-dialogs': !modalShow }">
          <div class="modal-content-custom" :class="{ 'modal-close': !modalShow,'size-custom':previewSize }">
              <div class="modal-body-custom">
                  
                  <span @click="closeModal()" class="close-icon-modal text-right" 
                  style="
                          z-index: 99999;
                          display: block; 
                          position: absolute;
                          right: 7px;
                          top: 7px;
                          cursor: pointer;
                          ">
                      <i class="fa fa-times-circle" style="font-size: 23px"></i>
                      <!-- <mdb-icon class="icon" icon="times-circle" /> -->
                  </span>
                  <h5 class="fw-bolder text-start">{{titleModal}}</h5>
                  <div>
                      <slot></slot>
                  </div>

                  <div class="text-end mt-4">
                      <button v-if="!oneButton" class="btn-cancel me-1" @click="closeModal()">
                          Cancelar</button>
                      <button class="btn-accept ms-1" @click="sendAccept()">
                          Aceptar
                      </button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
export default {
  name: 'Add',
  props:['titleModal','modal','oneButton','previewSize'],
  data() {
      return {
          
      }
  },
  computed:{
      modalShow(){
          return this.modal
      },
  },
  methods:{
      closeModal(){
        this.$emit('closeAddModal');
      },
      sendAccept(){
          this.$emit('acceptAddModal');
      }
  }
}
</script>
<style scoped>
.modal-dialogs {
background: hsla(0, 0%, 100%, 0.5) !important;
width: 100%;
position: fixed;
top: 0;
left: 0;
z-index: 9998;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
visibility: visible;
opacity: 1;
transition: 0.7s all ease-out;
}
.modal-content-custom {
max-width: 700px;
width: 90%;
opacity: 1;
transition: 0.7s all ease-out;
transform: translateY(0px);
background: white;
box-shadow: 0px 0px 14px 0px rgba(122, 122, 122, 0.75);
-webkit-box-shadow: 0px 0px 14px 0px rgba(122, 122, 122, 0.75);
-moz-box-shadow: 0px 0px 14px 0px rgba(122, 122, 122, 0.75);
border-radius: 10px;
}
.size-custom.modal-content-custom{
max-width: 1000px;
}
.modal-body-custom {
position: relative;
padding: 1rem;
}

.modal-dialogs.modal-close-dialogs {
visibility: hidden;
opacity: 0;
transition: 0.7s all ease-out;
}
.modal-content-custom.modal-close {
opacity: 0;
transform: translateY(-50px);
transition: 0.7s all ease-out;
}

.close-icon-modal {
text-align: right;
z-index: 99999;
}
.close-icon-modal .icon {
font-size: 20px;
background: white;
border-radius: 50%;
cursor: pointer;
}


.drag-drop-content_show {
min-height: 130px;
padding: 20px;
border: 2px dashed #07163b;
display: flex;
justify-content: center;
align-items: center;
}

.img-preview {
max-width: 460px;
}

.upload-img {
max-width: 80px;
opacity: 0.8 !important;
}
h5 {
color: #07163b;
font-size: 18px;
}
.btn-cancel {
border: 2px solid gray;
color: gray;
border-radius: 5px;
padding: 2px 7px;
font-size: 16px;
background: white;
}
.btn-accept {
border: 2px solid #07163b;
color: white;
background: #07163b;
border-radius: 3px;
padding: 2px 7px;
font-size: 16px;
}
</style>