<template>
  <div class="add-term">
    <button class="btn btn__admon btn-edit ms-1 btn-web" @click="openAdd()">
      Editar
    </button>
    <!-- <span class="btn-mobile" @click="openAdd()">Editar</span> -->
    <Add
      titleModal="Editar bloque"
      class="text-start"
      :modal="modalAddOpen"
      :disabled="isProcessing"
      @closeAddModal="closeAdd"
      @acceptAddModal="sendData()"
    >
      <p class="mt-3 mb-1 text-modal text-start">Título:</p>
      <input v-model="modelTitle" class="d-block w-100" type="text" />
      <span v-if="errorTitleAdd" class="error-input"
        >*Este campo debe ser llenado</span
      >
      <span v-if="errorNameTitle" class="error-input"
        >*Este campo debe contener de 5 a 50 caracteres.</span
      >
      <p class="mt-3 mb-1 text-modal text-start">Documento:</p>
      <!-- Texto adicional que muestra el archivo actual -->
      <div v-if="modelFile" class="file-info">
        <p>
          Se cuenta con el archivo
          <strong>{{ trimmedFileName }}</strong
          >.
        </p>
        <p>Si desea reemplazarlo, arrastre uno nuevo al recuadro.</p>
      </div>
      <div
        class="w-100 text-center drag-drop-content"
        :class="[
          !urlImg ? 'drag-drop-content_show' : 'drag-drop-content_hidden',
        ]"
        style="position: relative; overflow: clip"
      >
        <div>
          <div
            class="px-3"
            style="min-height: 145px; margin: auto"
            v-show="url"
          >
            <img
              src="https://kiritek-web-documents.s3.us-west-2.amazonaws.com/tres-marias/admin-tm/pdf.png"
              alt=""
            />
            <br />
            <label class="label-web mt-3" style="font-size: 12px">
              {{
                this.url.length > 40
                  ? `${this.url.substring(0, 40)}...${this.url.slice(-7)}`
                  : this.url
              }}
            </label>

            <label class="label-mobile mt-3" style="font-size: 12px">
              {{ `${this.url.substring(0, 20)}...${this.url.slice(-7)}` }}
            </label>
          </div>

          <img
            class="upload-img img-fluid"
            v-if="!urlImg"
            src="https://kiritek-web-documents.s3.us-west-2.amazonaws.com/tres-marias/img/adminBanners/nube.png"
            alt=""
          />
          <br v-if="!urlImg" />
          <label v-if="!urlImg" for="image">{{
            urlImg ? "" : "Da clic o arrastra tu archivo aquí"
          }}</label>
        </div>
        <input
          class="file-doc"
          type="file"
          accept="application/pdf"
          placeholder="url"
          id="url"
          @change="getFile"
          name="image"
        />
      </div>
      <div v-if="errorFile" class="text-start" style="margin-top: 5px">
        <span style="font-size: 14px; font-weight: 500; color: #9c251c"
          >*Debe seleccionar un archivo</span
        >
      </div>
      <div v-if="!pdfFile" class="text-start" style="margin-top: 5px">
        <span style="font-size: 14px; font-weight: 500; color: #9c251c"
          >*El archivo adjunto no se encuentra en el formato correcto. Debe ser
          un PDF</span
        >
      </div>
      <div v-if="errorNameFile" class="text-start" style="margin-top: 5px">
        <span style="font-size: 14px; font-weight: 500; color: #9c251c">
          *El nombre del archivo <b>no</b> debe contener caracteres especiales,
          espacios y debe tener un máximo de 50 caracteres
        </span>
      </div>
      <div v-if="errorSizeFile" class="text-start" style="margin-top: 5px">
        <span style="font-size: 14px; font-weight: 500; color: #9c251c"
          >*El peso del archivo <b>no</b> debe exceder de <b>5mb</b>.</span
        >
      </div>
    </Add>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
//import { VueEditor } from "vue2-editor";
import Add from "./../../../modals/Add.vue";
export default {
  name: "EditTerm",
  props: ["contentItem", "modalMobile"],
  components: {
    //VueEditor,
    Add,
  },
  computed: {
    ...mapGetters({
      terms: "getStateTerms",
    }),
    modalAddOpen() {
      if (this.modalMobile) {
        return this.modalMobile;
      } else {
        return this.modalAdd;
      }
    },
    id() {
      return this.contentItem._id;
    },
    order() {
      return this.contentItem.order;
    },
    urlImg() {
      return this.url;
    },
    trimmedFileName() {
      // Verifica si modelFile tiene un valor y no es null o undefined
      return this.modelFile
        ? this.modelFile.slice(16)
        : "Nombre del archivo no disponible";
    },
  },
  watch: {
    modalAddOpen(newVal) {
      if (newVal) {
        this.modelTitle = this.contentItem.title;
        this.modelContent = this.contentItem.content;
        this.modelFile = this.contentItem.file;
      }
    },
  },
  data() {
    return {
      //add modal
      modalAdd: false,
      isProcessing: false,
      modelTitle: null,
      modelContent: null,
      modelFile: null,
      errorTitleAdd: false,
      errorSizeFile: false,
      errorContentAdd: false,
      //toolbar
      customToolbar: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      //file
      file: null,
      url: "",
      dateUrl: null,
      //validations
      errorFile: false,
      pdfFile: true,
      errorNameFile: false,
      errorNameTitle: false,
    };
  },
  methods: {
    ...mapActions(["getTerms", "uploadTerms", "uploadTermPdf"]),
    openAdd() {
      this.modalAdd = true;
    },
    closeAdd() {
      this.modalAdd = false;
      this.modelTitle = "";
      (this.url = ""), (this.file = null);
      this.errorNameFile = false;
      this.errorNameTitle = false;
      this.errorFile = false;
      this.pdfFile = true;
      this.errorSizeFile = false;
      if (this.modalMobile) {
        this.$emit("closeModalEdit");
      }
      this.isProcessing = false; // Habilitar el botón nuevamente
    },
    getDateTime() {
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let newMonth = Number(month) + 1;
      if (day < 10) {
        /*  console.log("un digito") */
        day = `0${day}`;
      }
      if (month < 10) {
        if (newMonth < 10) {
          newMonth = `0${newMonth}`;
        }
        /* console.log("un digito") */
      }
      if (hour < 10) {
        hour = `0${hour}`;
        /* console.log("un digito") */
      }
      if (minute < 10) {
        minute = `0${minute}`;
        /* console.log("un digito") */
      }
      let datetime = `${day}-${newMonth}-${year}-${hour}${minute}`;
      this.dateUrl = datetime;
    },
    getFile(ev) {
      let files = ev.target.files;
      this.file = files;
      let validate = /^[a-zA-Z0-9._-]{0,54}$/;
      if (this.file) {
        if (this.file[0].name) {
          this.url = this.file[0].name;
          const fileName = this.file[0].name.toLowerCase(); // Obtenemos el nombre del archivo

          // Validar si el archivo es un PDF
          if (
            fileName.endsWith(".pdf") &&
            this.file[0].type === "application/pdf"
          ) {
            this.pdfFile = true;
          } else {
            this.pdfFile = false;
          }
        }
        this.errorFile = false; // No hay error con el archivo
        if (this.url.length > 53 || !validate.test(this.url)) {
          this.errorNameFile = true;
        } else {
          this.errorNameFile = false;
        }
        if (this.file[0].size >= 5000000) {
          this.errorSizeFile = true;
        } else {
          this.errorSizeFile = false;
        }
      } else {
        this.errorFile = true;
      }
    },
    validateTypeAdd(ret) {
      let validateFile = /^[a-zA-Z0-9._-]{0,54}$/;
      let validateTitle = /^[a-zA-ZÀ-ÿ0-9._\-\s]{5,50}$/;
      // Verifica que this.file es un objeto de tipo File
      const isFileValid = this.file && this.file[0] instanceof File;
      if (!isFileValid && this.contentItem.file) {
        //console.log(`(!isFileValid && this.contentItem.file)`);
        this.errorNameFile = false;
        this.errorNameTitle = false;
        if (ret) {
          return true;
        }
      }
      if (validateFile.test(this.url) && validateTitle.test(this.modelTitle)) {
        //   console.log(`(
        //   validateFile.test(this.url) &&
        //   validateTitle.test(this.modelTitle)
        // )`);
        if (
          this.url.toLowerCase().endsWith(".pdf") &&
          isFileValid && // Asegura que this.file esté definido
          this.file.type === "application/pdf"
        ) {
          //console.log(`El archivo no es un pdf`);
          this.pdfFile = false;
        }
        this.errorNameFile = false;
        this.errorNameTitle = false;
        if (ret) {
          return true;
        }
      } else if (
        !validateFile.test(this.url) &&
        validateTitle.test(this.modelTitle)
      ) {
        this.errorNameFile = true;
        this.errorNameTitle = false;
        if (ret) {
          return false;
        }
      } else if (
        validateFile.test(this.url) &&
        !validateTitle.test(this.modelTitle)
      ) {
        this.errorNameFile = false;
        this.errorNameTitle = true;
        if (
          this.url.toLowerCase().endsWith(".pdf") &&
          isFileValid && // Asegura que this.file esté definido
          this.file.type === "application/pdf"
        ) {
          //console.log(`El archivo no es un pdf`);
          this.pdfFile = false;
        }
        if (ret) {
          return false;
        }
      } else {
        this.errorNameFile = true;
        this.errorNameTitle = true;
        if (ret) {
          return false;
        }
      }
    },
    validateAdd() {
      // Verifica que this.file es un objeto de tipo File
      const isFileValid = this.file && this.file[0] instanceof File;
      //console.log(`isFileValid: ${isFileValid}`);

      if (
        this.modelTitle.length > 0 &&
        (isFileValid || this.contentItem.file)
      ) {
        this.errorTitleAdd = false;
        this.errorFile = false;

        // Verifica si el archivo es un PDF
        if (isFileValid && !this.file[0].name.endsWith(".pdf")) {
          this.pdfFile = false;
        }

        // Validación adicional
        if (this.validateTypeAdd("ret")) {
          this.validAdd = true;
        } else {
          this.validAdd = false;
        }
      } else {
        this.validAdd = false;
        this.errorNameFile = false;
        this.errorNameTitle = false;

        if (
          this.modelTitle.length === 0 &&
          (isFileValid || this.contentItem.file)
        ) {
          if (isFileValid && !this.file[0].name.endsWith(".pdf")) {
            this.pdfFile = false;
          }
          this.errorTitleAdd = true;
          this.errorFile = false;
          this.validateTypeAdd();
          this.errorNameTitle = false;
        } else if (
          this.modelTitle.length > 0 &&
          !isFileValid &&
          !this.contentItem.file
        ) {
          this.errorTitleAdd = false;
          this.errorFile = true;
          this.validateTypeAdd();
        } else {
          this.errorTitleAdd = true;
          this.errorFile = true;
          this.validateTypeAdd();
          this.errorNameTitle = false;
        }
      }
    },
    async sendData() {
      if (this.isProcessing) return; // Si ya está procesando, no hacer nada

      this.isProcessing = true; // Deshabilitar el botón
      /* console.log(this.validAdd) */
      this.validateAdd();
      this.getDateTime();
      /* console.log(this.validAdd) */
      let src;
      if (this.url && this.file[0]) {
        src = `${this.dateUrl}_${this.url}`;
      } else {
        src = `${this.modelFile}`;
      }
      //console.log(`this.validAdd en sendData(): ${this.validAdd}`);
      if (this.validAdd) {
        let data = {
          id: this.id,
          body: {
            title: this.modelTitle,
            order: this.order,
            file: src,
          },
        };

        try {
          if (data) {
            //console.log(`data: ${JSON.stringify(data, null, 2)}`);
            await this.uploadTerms(data);
          }
          const isFileValid = this.file && this.file[0] instanceof File;
          if (isFileValid) {
            await this.uploadTermPdf(this.file);
          }
          setTimeout(() => {
            this.getTerms();
            this.closeAdd();
          }, 500);
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>
<style scoped>
.add-term {
  display: inline-block;
}

.text-modal {
  font-weight: 500;
}

input.d-block {
  outline: 0;
  border: 1px solid #c0c4c6;
  font-size: 16px;
}

input.d-block::placeholder {
  font-size: 16px;
}

.btn__admon {
  padding: 3px 7px;
  background: transparent;
}
.btn-edit {
  border: 2px solid gray;
  border-radius: 3px;
  color: gray;
}
input.file-doc {
  position: absolute;
  left: 0px;
  top: 0px;
  min-height: 145px;
  width: 100%;
  opacity: 0;
}
.file-info {
  font-size: 14px;
  margin-top: 5px;
}

.file-info p {
  margin: 0;
  line-height: 1.5;
}
@media (min-width: 891px) {
  .btn-mobile {
    display: none;
  }
}
@media (max-width: 870px) {
  .btn-web {
    display: none;
  }
}
@media (min-width: 501px) {
  .label-mobile {
    display: none;
  }
}
@media (max-width: 500.67px) {
  button {
    font-size: 13.5px !important;
  }
  .label-web {
    display: none;
  }
}
</style>
