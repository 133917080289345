<template>
  <div>
    <div class="text-center">
      
    <amplify-authenticator>
      <amplify-sign-in slot="sign-in" hide-sign-up="true" ></amplify-sign-in>
        <amplify-sign-up
          slot="sign-up"
          hide-sign-up="true"
          :formFields.prop="formFields"
        ></amplify-sign-up>
    </amplify-authenticator>
  </div>
  </div>
</template>
<script>
    import {
        Auth,
        Hub
    } from "aws-amplify";

    export default {
        data() {
            return {
                authenticated: false,
                formFields: [{
                    type: "username",
                    label: "Username",
                    placeholder: "Username",
                    inputProps: {
                        required: true
                    },
                }, {
                    type: "password",
                    label: "Password",
                    placeholder: "Password",
                    inputProps: {
                        required: true
                    },
                }, {
                    type: "email",
                    label: "Email",
                    placeholder: "Email Address",
                    inputProps: {
                        required: true
                    },
                }, ],
            };
        },
        created() {
            this.signOut = false
        },
        mounted() {
            console.log("aaa");
            Auth.currentAuthenticatedUser()
                .then(() => {
                    this.authenticated = true;
                    this.$router.push("/admin/admin-tm");
                })
                .catch((error) => {
                    console.log(error);
                });
            Hub.listen("auth", (data) => {
                switch (data.payload.event) {
                    case "signIn":
                        console.log("this user sign in");
                        this.$router.push("/admin/admin-tm");
                        break;
                }
            });
        },
    };
</script>

<style>
     :root {
        --amplify-primary-color: #07163b;
        --amplify-primary-tint: #07163b;
        --amplify-primary-shade: #07163b;
    }
</style>
<style scoped>
    .hom {
        display: inline-block;
        color: #07163b;
    }
</style>