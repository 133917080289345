<template>
  <div>
    <div class="container-fluid">
      <div class="row py-4 row__title">
        <div class="col-12 mb-3 text-center pt-3 pb-3">
          <h1>ADMINISTRADOR DE BANNERS DEL SLIDER</h1>
        </div>
      </div>
    </div>
    <div class="container-fluid container__space">
      <div class="row py-3">
        <div class="col-lg-5 col-md-5 col-sm-5">
          <ShowVideo v-if="!load" />
          <load-video v-if="load" />
        </div>
        <div class="col-lg-7 col-md-7 col-sm-7">
          <div class="container__edit">
            <div class="edit-elements">
              <h5>Editar video</h5>
              <h6>Id del video:</h6>
              <br class="space" />
              <input
                type="text"
                name="video"
                :class="{ 'error-video-active': btnDisabled }"
                id="idVideoC"
                placeholder="Id del video"
                v-model="videoSrc"
                :disabled="edit"
                @keyup="empty()"
              /><br class="space-button" />
              <button
                @click="editElem()"
                class="btn__edit"
                :class="{
                  'btn__edit-active': !edit,
                  'error-btn-active': btnDisabled,
                }"
                :disabled="btnDisabled"
              >
                {{ edit ? "Editar" : "Guardar" }}
              </button>
              <br /><span v-if="btnDisabled" class="error-video"
                >*Este campo debe contener al menos 10 caracteres y un máximo de
                15.</span
              >
              <br /><br />
              <b
                ><span class="icon-question"
                content="<img id='imgIdCrucero' src='https://kiritek-web-documents.s3.us-west-2.amazonaws.com/tres-marias/admin-tm/IdCrucero.png'>" 
                v-tippy="{arrow : true, arrowType : 'round', animation : 'scale'}"
                  ><i class="fa fa-question"></i
                ></span>
                *El enlace del video será proporcionado por el líder del área.
                Se toma el id del enlace del video y se coloca.</b
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ShowVideo from "./Video.vue";
import LoadVideo from "./LoadVideo.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "EditVideo",
  components: {
    ShowVideo,
    LoadVideo,
  },
  computed: {
    ...mapGetters({
      videoCrucero: "getStateVideoCrucero",
    }),
  },
  data() {
    return {
      videoSrc: "",
      edit: true,
      btnDisabled: false,
      load: false,
    };
  },
  async created() {
    await this.getVideoCrucero();
    this.assignElement();
    this.id = this.videoCrucero[0]._id;
  },
  methods: {
    ...mapActions(["getVideoCrucero", "uploadVideoCrucero"]),
    async editElem() {
      this.edit = !this.edit;
      if (this.edit) {
        let data = {
          id: this.id,
          body: {
            claveVideo: this.videoSrc,
          },
        };
        try {
          await this.uploadVideoCrucero(data);
          setTimeout(() => {
            this.load = true;
          }, 100);
          setTimeout(() => {
            this.load = false;
          }, 1500);
        } catch (error) {
          console.log(error);
        }
      }
    },
    empty() {
      if (this.videoSrc.length < 10) {
        this.btnDisabled = true;
      } else {
        this.btnDisabled = false;
        if (this.videoSrc.length > 15) {
          this.btnDisabled = true;
        } else {
          this.btnDisabled = false;
        }
      }
    },
    assignElement() {
      setTimeout(() => {
        if (this.videoCrucero) {
          this.videoSrc = this.videoCrucero[0].claveVideo;
        } else {
          this.videoSrc = "";
        }
      }, 500);
    },
  },
};
</script>
<style>
#imgIdCrucero{
  max-width:300px!important;
}
@media (max-width:400px) {
  #imgIdCrucero{
  max-width:240px!important;
}
}
</style>
<style scoped>
h1 {
  font-size: 24px;
  font-weight: 700;
}
.row__title {
  background: white;
}
h5 {
  font-weight: 700;
  padding: 5px 10px;
  font-size: 20px;
}
h6 {
  font-size: 16px;
  padding: 0px 10px;
  font-weight: 600;
  display: inline-block;
}
b {
  font-size: 14px;
  margin-top: 2rem;
  display: inline-block;
}
.container__edit {
  display: flex;
  height: 100%;
  align-items: center;
}
.btn__edit {
  border: 2px solid #07163b;
  border-radius: 3px;
  color: #07163b;
  padding: 3px 7px;
  background: transparent;
  font-size: 14px;
}
.btn__edit.btn__edit-active {
  color: white;
  background: #07163b;
}
input {
  border: 1px solid gray;
  font-size: 15px;
  padding: 3px 6px;
  outline: 0;
}

.container__space {
  min-height: 75vh;
}
.error-video {
  font-size: 14px;
  font-weight: 500;
  color: rgb(156, 37, 28);
  margin-left: 10px;
}
.error-video-active {
  border: 2px solid rgb(156, 37, 28);
}
.error-btn-active {
  opacity: 0.7;
}
.icon-question {
  background: #07163b;
  color: white;
  padding: 5px 8px;
  font-size: 8px;
  border-radius: 10px;
  cursor:pointer
}

@media (min-width: 761px) {
  .space {
    display: none;
  }
}
@media (max-width: 760px) {
  input {
    display: inline-block;
    margin-left: 10px;
  }
}
@media (max-width: 576.67px) {
  .container__edit {
    padding: 10px 25px;
  }
  b {
    padding: 0px 10px;
  }
  .space {
    display: none;
  }
}
@media (max-width: 700px) {
  h1 {
    font-size: 20px;
  }
  b {
    text-align: justify;
    padding-right: 10px;
  }
}

@media (max-width: 500px) {
  .space {
    display: inline;
  }
}
@media (min-width: 401px) {
  .space-button {
    display: none;
  }
}
@media (max-width: 400px) {
  input {
    width: 100%;
    margin-right: 10px;
  }
  .btn__edit {
    margin: 15px 2px 3px 10px;
    width: 100%;
  }
  b {
    font-size: 13px;
  }
}
</style>