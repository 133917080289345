<template>
    <div id="modalv">
      <div
        class="modal-dialogs"
        :class="{ 'modal-close-dialogs': !modalShow }"
        @click.self="closeModal()"
      >
        <div class="modal-content-custom" :class="{ 'modal-close': !modalShow }">
          <div class="modal-body-custom">
            <div
              class="w-100 text-center mb-3 mt-3"
              style="position: relative; overflow: clip"
            >
              <img
                class="img-delete"
                src="https://kiritek-web-documents.s3.us-west-2.amazonaws.com/tres-marias/img/adminBanners/danger.png"
                alt=""
              />
              <h4 class="mt-2 mb-3">{{ msgDelete }}</h4>
              <button class="btn-cancel me-1" @click="closeModal()">Cancelar</button>
              <button class="btn-delete ms-1" @click="deleteElement()">Eliminar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "Delete",
    props: ["msgDelete","modal"],
    data() {
      return {
        modalf: true,
      };
    },
    computed: {
        modalShow(){
            return this.modal
        }
    },
    methods:{
        closeModal(){
          this.$emit('closeDeleteModal')
        },
        deleteElement(){
          this.$emit('deleteElement')
        }
    },
  };
  </script>
  <style scoped>
  .modal-dialogs {
    background: hsla(0, 0%, 100%, 0.5) !important;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9998;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: visible;
    opacity: 1;
    transition: 0.7s all ease-out;
  }
  .modal-content-custom {
    max-width: 700px;
    width: 90%;
    opacity: 1;
    transition: 0.7s all ease-out;
    transform: translateY(0px);
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 14px 0px rgba(122, 122, 122, 0.75);
    -webkit-box-shadow: 0px 0px 14px 0px rgba(122, 122, 122, 0.75);
    -moz-box-shadow: 0px 0px 14px 0px rgba(122, 122, 122, 0.75);
  }
  .modal-body-custom {
    position: relative;
    padding: 1rem;
  }
  
  .modal-dialogs.modal-close-dialogs {
    visibility: hidden;
    opacity: 0;
    transition: 0.7s all ease-out;
  }
  .modal-content-custom.modal-close {
    opacity: 0;
    transform: translateY(-50px);
    transition: 0.7s all ease-out;
  }
  
  .close-icon-modal {
    text-align: right;
    z-index: 99999;
  }
  .close-icon-modal .icon {
    font-size: 20px;
    background: white;
    border-radius: 50%;
    cursor: pointer;
  }
  .img-delete {
    max-width: 80px;
  }
  .btn-cancel{
    border:2px solid gray;
    color:gray;
    border-radius:5px;
    padding:2px 7px;
    font-size: 16px;
    background: white;
  }
  
  .btn-delete{
    border:2px solid #9c251c;
    color:white;
    background: #9c251c;
    border-radius:3px;
    padding:2px 7px;
    font-size: 16px;
  }
  </style>