<template>
    <div>
        <Instructions />
        <ActionsBanners />
    </div>
</template>
<script>
import Instructions from "./Instructions.vue"
import ActionsBanners from "./ShowBanner.vue"
export default {
    name:'Main',
    components:{
        Instructions,
        ActionsBanners
    }
}
</script>