<template>
    <div class="terms">
        <Title />
        <EditTerms />
    </div>
</template>
<script>
    import Title from "./Title"
    import EditTerms from "./EditTerms"
    export default {
        name: 'MainTerms',
        components: {
            Title,
            EditTerms
        }
    }
</script>
<style>
.terms{
    min-height:80vh;
    background:white
}
</style>