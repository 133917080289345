<template>
  <div class="video__loader">
    <div class="d-flex justify-content-center loader__content">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoadVideo",
};
</script>
<style scoped>
.video__loader{
    height: 100%;
}
.loader__content{
    height: 100%;
    align-items: center;
}
</style>