<template>
  <div class="row">


    <div class="col-lg-1 col-md-1 col-sm-2 col-2">{{ textItem.order }}</div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-8">{{ textItem.title }}
    </div>
    <div class="col col-mobile">
      <div class="terms__buttons-content-web">
        <span class="link__document me-3" @click="openTerms(textItem.file)">Ver documento</span><!-- `${URL}${textItem.file}` -->
        <DeleteTerm :contentItem="arrItem" />
        <Edit :contentItem="arrItem" />
      </div>
      <div class="terms__buttons-content-mobile btn-group dropstart">
         <OptionMobile :contentItem="arrItem" />
        </div>
    </div>
    
    <!-- <div class="accordion accordion-flush" :id="idCollapse">
      <div class="accordion-item">
        <h2 class="accordion-header" :id="flushHeandig">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
            :data-bs-target="`#${flushCollapse}`" aria-expanded="false" :aria-controls="idCollapse">
            {{ title }}
          </button>
        </h2>
        <div :id="flushCollapse" class="accordion-collapse collapse" :aria-labelledby="flushHeandig"
          :data-bs-parent="`#${idCollapse}`">
          <div class="accordion-body accordion-terms">
            <div class="content" v-html="content">

            </div>
            <div class="text-end action-buttons">
              <DeleteTerm :contentItem="arrItem" />
              <Edit :contentItem="arrItem" />
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import Edit from "./EditTerm.vue"
import DeleteTerm from "./DeleteTerm.vue"
import OptionMobile from "./TermsOptions.vue"
export default {
  name: "ItemTerms",
  props: ["idItem", "textItem"],
  components: {
    Edit,
    DeleteTerm,
    OptionMobile
  },
  data() {
    return {
      url: 'https://kiritek-web-documents.s3.us-west-2.amazonaws.com/tres-marias/assets/images/main-banner/terms/'
    }
  },
  computed: {
    idCollapse() {
      let index = this.idItem + 1
      let id = "collapseTerm" + index
      return id
    },
    flushCollapse() {
      let index = this.idItem + 1
      let flush = "flush-collapse" + index
      return flush
    },
    flushHeandig() {
      let index = this.idItem + 1
      let flush = "flush-heandig" + index
      return flush
    },
    arrItem() {
      return this.textItem
    },
    title() {
      return this.textItem.title
    },
    content() {
      return this.textItem.content
    }
  },
  methods:{
    openTerms(doc) {
      let URL = `${this.url}${doc}`
      console.log(URL)
      window.open(
        URL
      );
    },
  }

};
</script>
<style>
.accordion {
  background: transparent !important;
}

.accordion-item {
  background: transparent !important;
}

button.accordion-button.collapsed {
  background: transparent !important;
  color: black !important;
  font-weight: 600;
}

.accordion-button {
  background: #07163b !important;
  color: white !important;
}

.accordion-button:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-body.accordion-terms {
  padding: 0 !important;
}
</style>
<style scoped>
.content {
  max-height: 150px;
  overflow-y: auto;
  padding: 15px 15px;
  background: white;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
}

.text-end {
  padding: 10px 15px;
}

.link__document {
  color: black;
  text-decoration: underline;
  cursor:pointer
}


@media(min-width:871px) {
  .terms__buttons-content-web {
    display: flex;
    align-items: center;
  }
  .terms__buttons-content-mobile{
    display: none;
  }
}

@media(max-width:870px) {
  .terms__buttons-content-web {
    display: none
  }
  .terms__buttons-content-mobile{
   width: 100%; 
  }
}
</style>