<template>
  <div class="banner__admon">
    <div class="container-fluid container__table">
      <!-- <div class="row">
            <div class="col-12" v-for="(banner, index) in banners" :key="index">
                <img :src="banner.url" >
            </div>
        </div> -->
      <div class="row" v-if="!order">
        <div class="col-12 px-4">
          <!--  <h1 class="mt-5 text-center">BANNERS TM</h1> -->
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Orden</th>
                <th scope="col">Imagen</th>
                <th scope="col">Micrositio</th>
                <th class="col-link" scope="col">Link</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody v-if="banners.length > 0">
              <!--   <draggable v-model="bannersArr" group="people" @start="drag=true" @end="drag=false"> -->
              <tr v-for="(banner, index) in banners" :key="index">
                <th scope="row">{{ index + 1 }}</th>
                <td >
                  <!-- <span v-if="banner.url.includes('.mp4')">a</span> -->
                  <iframe  style="max-width: 100px;max-height: 40px" v-if="banner.url.includes('.mp4')" :src="`${bucket}${banner.url}`" title="YouTube video" allowfullscreen></iframe>
                  <img
                    v-if="!banner.url.includes('.mp4')"
                    class="img-preview"
                    
                    :src="`${bucket}${banner.url}`"
                    style="max-width: 100px"
                  />
                  <span @click="indexElement = index" 
                  style="display: block;text-align: center;max-width:100px;text-decoration: underline;cursor: pointer;">
                  Ver
                </span>
                </td>
                <td class="name-microsite" :class="{'text-center':banner.microsite=='no aplica'}">
                  {{ banner.microsite =='no aplica' ? '-': banner.microsite }}
                </td>
                <td class="link-microsite col-link" :class="{'text-center':banner.microsite=='no aplica'}">
                  <span class="link-web">
                    {{banner.microsite=='no aplica'?'-':`https://tresmarias.com.mx${banner.link}`}}
                  </span>
                  <span style="text-decoration:none" class="link-mobile" v-if="banner.microsite=='no aplica'">-</span>
                  <span :content="`https://tresmarias.com.mx${banner.link}`" 
                  v-tippy="{arrow : true, arrowType : 'round', animation : 'scale',trigger : 'click'}" class="link-mobile" v-else>
                    Ver link
                  </span>
                  
                  <span></span>
                </td>
                <td class="btns-web">
                  <button
                    class="btn__admon btn-delete me-1"
                    @click="deleteElement(banner)"
                  >
                    Eliminar
                  </button>
                  <button
                    class="btn__admon btn-edit ms-1"
                    @click="editElement(banner)"
                  >
                    Editar
                  </button>
                </td>
                <td class="btns-mobile">
                  <div class="btn-group dropstart">
                    <button
                      type="button"
                      class=" dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="dropdownAdmon"
                    >
                      <i style="font-size:15px" class="fa fa-ellipsis-v"></i>
                    </button>
                    <ul class="dropdown-menu" >
                      <li><a class="dropdown-item txt-delete" href="#" @click.prevent="deleteElement(banner)">Eliminar</a></li>
                      <li><a class="dropdown-item txt-edit" href="#" @click.prevent="editElement(banner)">Editar</a></li>
                    </ul>
                  </div>
                </td>
              </tr>
              <!-- </draggable> -->
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="6" class="tex-center">
                  <msj-default></msj-default>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <CoolLightBox 
      :items="arrImgLigthbox" 
      :index="indexElement"
      @close="indexElement = null">
    </CoolLightBox>

      <!-- <div  > -->
      <EditOrder
        v-if="order"
        :orderBtn="order"
        @saveOrder="saveEmit"
        @cancelOrder="cancelEmit"
      />

      <!-- </div> -->
    </div>
    <div class="container-fluid text-end py-3 content__button">
      <button v-if="!order" @click="order = !order" class="btn btn-order me-1">
        Cambiar orden
      </button>
      <button @click="createBanner()" v-if="!order" class="btn btn-admin ms-1">Agregar banner</button>
      <div class="row mt-5 text-start">
        <div class="col-12"></div>
      </div>
      <ModalCreate :modalCreate="modalCreate" @modalClose="closeCreate" />
      <modal :modalUpdate="modal" @modalClose="close" :dataBanner="banner" />
      <ModalDelete
        :modalDelete="modaldel"
        :dataDelete="banner"
        @closeDelete="cancelDelete"
      />
    </div>
  </div>
</template>
<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import ModalCreate from "./modalActions/ModalCreate.vue";
import Modal from "./modalActions/ModalUpdate.vue";
import ModalDelete from "./modalActions/ModalDelete.vue";
import EditOrder from "./EditOrder.vue";
import MsjDefault from '../MsjDefault.vue';
//import draggable from 'vuedraggable'

import { mapActions, mapGetters } from "vuex";
export default {
  name: "showBanner",
  computed: {
    ...mapGetters({
      banners: "getStateBanners",
      images:"getStateImgsPreview"
    }),
    arrImgLigthbox(){
      return this.images
    }
  },
  components: {
    ModalCreate,
    Modal,
    ModalDelete,
    EditOrder,
    CoolLightBox,
    MsjDefault
    //draggable
  },
  data() {
    return {
      //var for lightbox
      indexElement:null,
      //data to send
      file: null,
      url: "",
      bannersArr: null,
      microsite: "",
      linkMicrosite: null,
      dateUrl: null,
      //order
      order: false,
      saveOrder: false,
      //otherdata
      bucket:
        "https://kiritek-web-documents.s3.us-west-2.amazonaws.com/tres-marias/assets/images/main-banner/",
      banner: null,
      modalCreate:false,
      modal: false,
      modaldel: false,
      micrositesArr: [
        {
          name: "no aplica",
          link: "no aplica",
        },
        {
          name: "canadas",
          link: "/canadas/casas",
        },
        {
          name: "torres paseo del parque",
          link: "/torrespaseodelparque/departamentos",
        },
        {
          name: "ciudad salud",
          link: "/ciudadsalud/zonacomercial",
        },
        {
          name: "alamos",
          link: "/alamos/terrenos",
        },
      ],
      //var imgs lightbox
      arrLightbox:[]
    };
  },
  methods: {
    ...mapActions(["getBanners", "addBanner", "uploadImg"]),
    getDateTime() {
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();
      let hour = date.getHours();
      let minute = date.getMinutes();
      if (day < 10) {
        /*  console.log("un digito") */
        day = `0${day}`;
      }
      if (month < 10) {
        month = `0${month}`;
        /* console.log("un digito") */
      }
      if (hour < 10) {
        hour = `0${hour}`;
        /* console.log("un digito") */
      }
      if (minute < 10) {
        minute = `0${minute}`;
        /* console.log("un digito") */
      }
      let datetime = `${day}-${month + 1}-${year}-${hour}:${minute}`;
      this.dateUrl = datetime;
    },
    getSelectValue() {
      let selectData = document.getElementById("microsite");
      this.microsite = selectData.value;
      let urlFile = document.getElementById("url");
      this.url = urlFile.files[0].name;
    },
    compareMicrosite() {
      this.getSelectValue();
      for (let index = 0; index < this.micrositesArr.length; index++) {
        if (this.micrositesArr[index].name === this.microsite) {
          this.linkMicrosite = this.micrositesArr[index].link;
        }
      }
    },
    getFile(e) {
      let files = e.target.files;
      this.file = files;
    },
    editElement(element) {
      this.modal = true;
      this.banner = element;
    },
    deleteElement(id) {
      this.banner = id;
      this.modaldel = true;
    },
    cancelDelete() {
      this.banner = null;
      this.modaldel = false;
    },
     createBanner() {
      this.modalCreate=true;
    },
    closeCreate() {
      this.modalCreate=false;
    },
    close() {
      this.modal = false;
      this.banner = null;
    },
    saveEmit(e) {
      console.log(e);
    },
    cancelEmit() {
      this.order = false;
    },
    async sendBanner() {
      this.getSelectValue();
      this.compareMicrosite();
      this.getDateTime();
      let src = `${this.dateUrl}_${this.url}`;
      let orderBanner = this.banners.length + 1;
      let body = {
        url: src,
        microsite: this.microsite,
        link: this.linkMicrosite,
        order: orderBanner,
      };
      //console.log(body);
      try {
        await this.addBanner(body);
        console.log("uno");
        await this.uploadImg(this.file);
        console.log("dos");
        setTimeout(() => {
          this.getBanners();
          console.log("tres");
          this.file = null;
        }, 2000);
      } catch (error) {
        console.log(error);
      }
    },
  },
  async created() {
    await this.getBanners();
    //this.bannersArr=this.banners
  },
};
</script>
<style>
#dropdownAdmon::before {
  border: none;
}
#dropdownAdmon{
  border: none;
}

</style>
<style scoped>
.tippy-tooltip{
  font-size: 10px!important;
}
img {
  width: 100%;
}
.btn-admin {
  background: #07163b;
  color: white;
  border: none;
  border: 2px solid #07163b;
}
.btn-order {
  background: white;
  color: #07163b;
  border: 2px solid #07163b;
}
.content__button {
  background: white;
}
.btn__admon {
  padding: 3px 7px;
  background: transparent;
}
.btn-delete {
  border: 2px solid #9c251c;
  border-radius: 3px;
  color: #9c251c;
}
.btn-edit {
  border: 2px solid gray;
  border-radius: 3px;
  color: gray;
}
.name-microsite {
  text-transform: capitalize;
}
.link-microsite{
  font-weight: 500;
}
.link-mobile{
  text-decoration: underline;
}
.img-preview{
  cursor:zoom-in
}
.banner__admon {
  min-height: 65vh;
  background: white;
}
.container__table {
  background: #f0f0f0;
}
th,td{
  vertical-align: middle;
}
.btns-mobile{
  text-align: center;
  vertical-align: middle;
}
.txt-delete{
  color: #9c251c;
  font-weight: 500;
  font-size: 15px;
}
.txt-delete:active{
  color: #9c251c;
}
.txt-edit{
  font-weight: 500;
  font-size: 15px;
}
.txt-edit:active{
  background: rgb(233, 236, 239);
  color:black
}
.txt-delete:hover{
  background: #9c251c2b;
}
tbody tr:last-child{
  border: 0px solid transparent;
}

@media (min-width: 871px) {
  .btns-mobile{
    display: none;
  }
  .col-link{
    width: 30%;
  }
}
@media(max-width:700px){
  th,td{
  font-size:14px;
}
}
@media (max-width: 870.67px) {
  .btns-web{
    display: none;
  }
  .col-link{
    width: 35%;
  }
}
@media (min-width:501px){
  .link-mobile{
    display: none;
  }
}
@media (max-width: 500.67px) {
  .content__button button{
    font-size: 13.5px;
  }
  .link-web{
    display: none;
  }
}

</style>