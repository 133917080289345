<template>
  <div>
    <div  class="wrapper mb-4" >
      <div
        style="padding-top: 20px"
        class="container-video-crucero ratio ratio-16x9"
      >
        <youtube
          width="100%"
          ref="youtube"
          class="container-mobile-video"
          :video-id="videoIdSv"
          :player-vars="playerVars"
          @playing="playing"
          @error="error"
        ></youtube>
      </div> 
    </div>
  </div>
</template>

<script>
import emailjs from '@emailjs/browser';
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Video",
  components: {
  },
  computed:{
    ...mapGetters({
      videoCrucero:"getStateVideoCrucero"
    }),
    videoIdSv(){
      this.assignElement();
      return this.videoId
    },
  },
  data() {
    return {
      from_name: "VIDEO CRUCERO TRES MARÍAS",
      to_name: "PALOMO",
      from_email: "brayanesquivel777.com",
      message: "El video del crucero dejo de funcionar",
      date: "",
      hour: "",
      error_video:false,
      videoId: "", 
      playerVars: {
        autoplay: 1,
        mute: 1,
        playsinline: 1,
        
      },
    };
  },
  mounted() {
      this.playVideo();
  },
  async created(){
    await this.getVideoCrucero();
    this.assignElement();
  },
  methods: {
    ...mapActions(['getVideoCrucero']),
    playing() {
        console.log("reproduciendo");
       
    },
    error(){
      console.log("error video")
      // this.sendErrorVideo()
     
    },
    playVideo() {
      this.$refs.youtube.player.playVideo();
    },

     sendErrorVideo() {
      var hoy = new Date();
      this.date = hoy.getDate() + '/' + ( hoy.getMonth() + 1 ) + '/' + hoy.getFullYear();
      this.hour = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();


      let data = {
        to_name: this.to_name,
        from_name: this.from_name,
        from_email: this.from_email,
        message: this.message,
        date: this.date,
        hour: this.hour
        
      };

      emailjs
        .send("service_rapwzwa", "template_1e3stox", data, "CUQbgjJeAf5Ezi8eE")
        .then(
          function (response) {
            if (response.text === "OK") {
              // alert("El correo se ha enviado de forma exitosa");
            }
            console.log(
              "SUCCESS. status=%d, text=%s",
              response.status,
              response.text
            );
          },
          function (err) {
            alert("Ocurrió un problema al enviar el correo");
            console.log("FAILED. error=", err);
          }
        );
    },
    assignElement() {
      setTimeout(() => {
        if (this.videoCrucero) {
          this.videoId = this.videoCrucero[0].claveVideo;
        } else {
          this.videoId = "";
        }
      }, 500);
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding-top: 20px !important;
  /* min-height: 80vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-video-crucero {
  width: 90%;
  margin-left: auto;
   margin-right: auto;
}
.container-mobile-video {
  width: 100%;
}
.error__box{
   width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.error__title{
  font-weight: 700;
  color: #14274a;
}
@media (max-width: 991px) {
  .container-video-crucero {
    width: 90%;
    margin-left: auto;
    margin-right:auto ;
    margin-top: 10px;
  }
}

@media (max-width:700px){
  .wrapper.mb-4 {
  min-height:43vh!important;

  }
}
@media(max-width:576.67px){
  .container-video-crucero {
    max-width: 400px;
  }
}
@media (max-width:600px){
  .wrapper.wrapper.mb-4 {
  min-height:auto!important;

  }
}

</style>