<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 py-3 pb-4" content="Presiona y arrastra los elementos para cambiar el orden"
                v-tippy="{ arrow: true, arrowType: 'round', animation: 'scale' }">
                <h5>Editar orden</h5>
                <draggable v-model="termsArr" group="people" @start="drag = true" @end="drag = false" class="dragg">
                    <div class="row__banner-editorder" v-for="(term, index) in termsArr" :key="index">
                        <span class="fw-bolder">{{ index + 1 }}</span>
                        <span class="title-col">{{ term.title }}</span>
                    </div>
                </draggable>

            </div>
            <div class="pt-3 content__button text-end">
                <h6 class="mt-3">Presiona y arrastra los elementos para cambiar el orden.</h6>
                <button v-if="order" class="btn btn-order me-1" @click="cancelOrder()">
                    Cancelar
                </button>
                <button v-if="order" class="btn btn-admin ms-1" @click="saveOrder();">
                    Guardar orden
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import draggable from "vuedraggable";
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'Order',
    props: ["orderBtn"],
    components: {
        draggable,
    },
    computed: {
        ...mapGetters({
            terms: 'getStateTerms'
        }),
        order() {
            return this.orderBtn
        }
    },
    data() {
        return {
            termsArr: null,
        }
    },
    methods: {
        ...mapActions(['getTerms', 'uploadTerms']),
        cancelOrder(){
            this.$emit('cancelOrder')
        },
        async saveOrder(){
            
            for (let index = 0; index < this.termsArr.length; index++) {
                const element = this.termsArr[index];
                const data={
                    id:element._id,
                    body:{
                        title:element.title,
                        order:index+1,
                        content:element.content
                    }
                }
                try {
                    await this.uploadTerms(data);
                } catch (error) {
                    console.log(error)
                }
            }
            setInterval(() => {
                this.getTerms();
            }, 700);
            setInterval(() => {
                this.cancelOrder();
            }, 1700);
        },
        
    },
    async created() {
        await this.getTerms()
        this.termsArr = this.terms
    }
}
</script>
<style scoped>
.dragg div {
    border-bottom: 1px solid silver;
    border-top: none;
}

/* .dragg div:first-child{
    border-bottom: 1px solid gray;
    border-top: none;
} */
.dragg div:first-child {
    border-top: 1px solid silver;
    border-bottom: 1px solid silver;
}

.row__banner-editorder{
    padding: 16px 20px;
}
.btn-admin {
    background: #07163b;
    color: white;
    border: none;
    border: 2px solid #07163b;
}

.btn-order {
    background: white;
    color: #07163b;
    border: 2px solid #07163b;
}

.content__button {
    background: white;
}

h5 {
    font-weight: 700;
    padding: 5px 10px;
    font-size: 20px;
}

h6 {
    font-size: 15px;
    padding: 0px 10px;
    font-weight: 600;
    display: inline-block;
}
.title-col{
    margin: 0 3rem;
    font-weight: 600;
}
@media (max-width:500.67px){
    button{
        font-size: 13.5px!important;
    }
}
</style>
