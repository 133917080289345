<template>
  <div class="add-term">
    <button :disabled="showTerms" class="btn btn-admin ms-1" @click="openAdd()">
      Agregar término
    </button>
    <Add
      titleModal="Agregar bloque"
      class="text-start"
      :modal="modalAddOpen"
      :disabled="isProcessing"
      @closeAddModal="closeAdd()"
      @acceptAddModal="sendData()"
    >
      <p class="mt-3 mb-1 text-modal text-start">Título:</p>
      <input
        v-model="titleInputAdd"
        class="d-block w-100"
        type="text"
        placeholder="Agrega un título con al menos 5 caracteres"
      />
      <span v-if="errorTitleAdd" class="error-input"
        >*Este campo debe ser llenado</span
      >
      <span v-if="errorNameTitle" class="error-input"
        >*Este campo debe contener de 5 a 100 caracteres.</span
      >
      <p class="mt-3 mb-1 text-modal text-start">Documento:</p>
      <div
        class="w-100 text-center drag-drop-content"
        :class="[!url ? 'drag-drop-content_show' : 'drag-drop-content_hidden']"
        style="position: relative; overflow: clip"
      >
        <div>
          <div
            class="px-3"
            style="min-height: 145px; margin: auto"
            v-show="url"
          >
            <img
              src="https://kiritek-web-documents.s3.us-west-2.amazonaws.com/tres-marias/admin-tm/pdf.png"
              alt=""
            />
            <br />
            <label class="label-web mt-3" style="font-size: 12px">
              {{
                url.length > 40
                  ? `${url.substring(0, 40)}...${url.slice(-7)}`
                  : url
              }}
            </label>

            <label class="label-mobile mt-3" style="font-size: 12px">
              {{ `${url.substring(0, 20)}...${url.slice(-7)}` }}
            </label>
          </div>

          <img
            class="upload-img img-fluid"
            v-if="!url"
            src="https://kiritek-web-documents.s3.us-west-2.amazonaws.com/tres-marias/img/adminBanners/nube.png"
            alt=""
          />
          <br v-if="!url" />
          <label v-if="!url" for="image">{{
            url ? "" : "Da clic o arrastra tu archivo aquí"
          }}</label>
        </div>
        <input
          class="file-doc"
          type="file"
          accept="application/pdf"
          placeholder="url"
          id="url"
          @change="getFile"
          name="image"
        />
      </div>
      <div v-if="errorFile" class="text-start" style="margin-top: 5px">
        <span style="font-size: 14px; font-weight: 500; color: #9c251c"
          >*Debe seleccionar un archivo</span
        >
      </div>
      <div v-if="!pdfFile" class="text-start" style="margin-top: 5px">
        <span style="font-size: 14px; font-weight: 500; color: #9c251c"
          >*El archivo adjunto no se encuentra en el formato correcto. Debe ser
          un PDF</span
        >
      </div>
      <div v-if="errorNameFile" class="text-start" style="margin-top: 5px">
        <span style="font-size: 14px; font-weight: 500; color: #9c251c">
          *El nombre del archivo <b>no</b> debe contener espacios, ni acentos,
          ni caracteres especiales, ni 'ñ', y debe tener un máximo de 100
          caracteres.
        </span>
      </div>
      <div v-if="errorSizeFile" class="text-start" style="margin-top: 5px">
        <span style="font-size: 14px; font-weight: 500; color: #9c251c"
          >*El peso del archivo <b>no</b> debe exceder de <b>5mb</b>.</span
        >
      </div>
      <div v-if="errorSendData" class="text-start" style="margin-top: 5px">
        <span style="font-size: 14px; font-weight: 500; color: #9c251c"
          >*Error al enviar la información: {{errorSendDataMsn}}.</span
        >
      </div>
    </Add>
    <!-- Icono de carga rotando -->
    <div v-if="loading" class="loading-overlay">
      <div class="spinner"></div> <!-- Spinner con CSS -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Add from "./../../../modals/Add.vue";
export default {
  name: "AddTerm",
  props: [],
  components: {
    Add,
  },
  computed: {
    ...mapGetters({
      terms: "getStateTerms",
    }),
    modalAddOpen() {
      return this.modalAdd;
    },
    showTerms() {
      if (this.terms.length >= 4) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      isProcessing: false,
      //add modal
      modalAdd: false,
      titleInputAdd: "",
      contentInputAdd: ``,
      validAdd: null,
      errorTitleAdd: false,
      errorSizeFile: false,
      errorContentAdd: false,
      errorSendData: false,
      errorSendDataMsn: '',
      loading: false,
      //toolbar
      customToolbar: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      //file
      id: null,
      file: null,
      url: "",
      dateUrl: null,
      //validatios
      errorFile: false,
      pdfFile: true,
      errorNameFile: false,
      errorNameTitle: false,
    };
  },
  watch: {
    titleInputAdd(newValue) {
      // Se ejecuta cuando 'titleInputAdd' cambia
      if (newValue) {
        if (newValue.length < 5 || newValue.length > 100) {
          this.errorTitleAdd = false;
          this.errorNameTitle = true; // Longitud incorrecta
        } else {
          this.errorTitleAdd = false;
          this.errorNameTitle = false;
      }
      } 
    },
  },
  methods: {
    ...mapActions(["getTerms", "addTerms", "uploadTermPdf"]),
    openAdd() {
      this.modalAdd = true;
    },
    closeAdd() {
      this.modalAdd = false;
      this.titleInputAdd = "";
      this.url = "";
      this.file = null;
      this.errorNameFile = false;
      this.errorNameTitle = false;
      this.errorTitleAdd = false;
      this.errorFile = false;
      this.pdfFile = true;
      this.errorSizeFile = false;
      this.errorSendDataMsn= '',
      this.errorSendData = false;
      this.isProcessing = false; // Habilitar el botón nuevamente
    },
    validateTypeAdd(ret) {
      let validateFile = /^[a-zA-Z0-9._-]{0,100}$/;
      let validateTitle = /^[a-zA-ZÀ-ÿ0-9._\-\s]{5,100}$/;
      if (
        validateFile.test(this.url) &&
        validateTitle.test(this.titleInputAdd)
      ) {
        if (
          this.url.toLowerCase().endsWith(".pdf") &&
          this.file && // Asegura que this.file esté definido
          this.file.type === "application/pdf"
        ) {
          console.log(`El archivo no es un pdf`);
          this.pdfFile = false;
        }
        this.errorNameFile = false;
        this.errorNameTitle = false;
        if (ret) {
          return true;
        }
      } else if (
        !validateFile.test(this.url) &&
        validateTitle.test(this.titleInputAdd)
      ) {
        this.errorNameFile = true;
        this.errorNameTitle = false;
        if (ret) {
          return false;
        }
      } else if (
        validateFile.test(this.url) &&
        !validateTitle.test(this.titleInputAdd)
      ) {
        this.errorNameFile = false;
        this.errorNameTitle = true;
        if (
          this.url.toLowerCase().endsWith(".pdf") &&
          this.file && // Asegura que this.file esté definido
          this.file.type === "application/pdf"
        ) {
          // console.log(`El archivo no es un pdf`);
          this.pdfFile = false;
        }
        if (ret) {
          return false;
        }
      } else {
        this.errorNameFile = true;
        this.errorNameTitle = true;
        if (ret) {
          return false;
        }
      }
    },
    validateAdd() {
      if (this.titleInputAdd.length > 0 && this.file) {
        if (this.file[0].name) {
          const fileName = this.file[0].name.toLowerCase(); // Obtenemos el nombre del archivo

          // Validar si el archivo es un PDF
          if (
            fileName.endsWith(".pdf") &&
            this.file[0].type === "application/pdf"
          ) {
            this.pdfFile = true;
          } else {
            this.pdfFile = false;
          }
        }
        this.errorFile = false; // No hay error con el archivo
        this.errorTitleAdd = false;
        if (this.validateTypeAdd("ret")) {
          this.validAdd = true;
        } else {
          this.validAdd = false;
        }
      } else {
        this.validAdd = false;
        this.errorNameFile = false;
        this.errorNameTitle = false;
        if (this.titleInputAdd.length == 0 && this.file) {
          if (this.file[0].name) {
            const fileName = this.file[0].name.toLowerCase(); // Accede al nombre del archivo
            if (
              fileName.endsWith(".pdf") &&
              this.file[0].type === "application/pdf"
            ) {
              // console.log(`El archivo es un PDF válido`);
              this.pdfFile = true;
            } else {
              // console.log(`El archivo no es un PDF`);
              this.pdfFile = false;
            }
          }
          this.errorTitleAdd = true;
          this.errorFile = false;
          this.validateTypeAdd();
          this.errorNameTitle = false;
        } else if (this.titleInputAdd.length > 0 && !this.file) {
          this.errorTitleAdd = false;
          this.errorFile = true;
          this.validateTypeAdd();
        } else {
          this.errorTitleAdd = true;
          this.errorFile = true;
          this.validateTypeAdd();
          this.errorNameTitle = false;
        }
      }

      // console.log(this.validAdd);
    },
    getDateTime() {
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();
      let hour = date.getHours();
      let minute = date.getMinutes();
      let newMonth = Number(month) + 1;
      if (day < 10) {
        /*  console.log("un digito") */
        day = `0${day}`;
      }
      if (month < 10) {
        if (newMonth < 10) {
          newMonth = `0${newMonth}`;
        }
        /* console.log("un digito") */
      }
      if (hour < 10) {
        hour = `0${hour}`;
        /* console.log("un digito") */
      }
      if (minute < 10) {
        minute = `0${minute}`;
        /* console.log("un digito") */
      }
      let datetime = `${day}-${newMonth}-${year}-${hour}${minute}`;
      this.dateUrl = datetime;
    },
    getFile(ev) {
      this.isProcessing = false;
      let files = ev.target.files;
      this.file = [...files];
      let validate = /^[a-zA-Z0-9._-]{0,100}$/;
      if (this.file) {
        if (this.file[0].name) {
          this.url = this.file[0].name;
          const fileName = this.file[0].name.toLowerCase(); // Obtenemos el nombre del archivo

          // Validar si el archivo es un PDF
          if (
            fileName.endsWith(".pdf") &&
            this.file[0].type === "application/pdf"
          ) {
            this.pdfFile = true;
          } else {
            this.pdfFile = false;
          }
        }
        this.errorFile = false; // No hay error con el archivo
        if (this.url.length > 99 || !validate.test(this.url)) {
          this.errorNameFile = true;
        } else {
          this.errorNameFile = false;
        }
        if (this.file[0].size >= 5000000) {
          this.errorSizeFile = true;
        } else {
          this.errorSizeFile = false;
        }
        ev.target.value = '';
      } else {
        this.errorFile = true;
      }
    },
    async sendData() {
      if (this.errorFile || this.errorContentAdd || this.errorNameFile || this.errorNameTitle || this.errorSendData || this.errorSizeFile || this.errorTitleAdd) {
         // console.log('Error en algún campo');
         return;
      }
      if (this.isProcessing) return; // Si ya está procesando, no hacer nada

      this.isProcessing = true; // Deshabilitar el botón

      /* console.log(this.validAdd) */
      this.validateAdd();
      /* console.log(this.validAdd) */
      this.getDateTime();
      let src = `${this.dateUrl}_${this.url}`;
      if (this.validAdd) {
        let lastTermOrder =
          this.terms.length > 0 ? this.terms[this.terms.length - 1].order : 0;
        let body = {
          title: this.titleInputAdd,
          order: lastTermOrder + 1,
          file: src,
        };
        // console.log(body);
        try {
          this.loading = true;
          await this.addTerms(body);
          await this.uploadTermPdf(this.file);
          setTimeout(() => {
            this.getTerms();
            this.closeAdd();
          }, 500);
        } catch (error) {
          this.errorSendData = true;
          this.errorSendDataMsn = error;
        } finally {
          this.loading = false;
          this.isProcessing = false;
        }
      }
    },
  },
};
</script>
<style scoped>
.add-term {
  display: inline-block;
}

.text-modal {
  font-weight: 500;
}

input.d-block {
  outline: 0;
  border: 1px solid #c0c4c6;
  font-size: 16px;
}

input.d-block::placeholder {
  font-size: 16px;
}

.btn-admin {
  background: #07163b;
  color: white;
  border: none;
  border: 2px solid #07163b;
}

input.file-doc {
  position: absolute;
  left: 0px;
  top: 0px;
  min-height: 145px;
  width: 100%;
  opacity: 0;
}

@media (min-width: 501px) {
  .label-mobile {
    display: none;
  }
}
@media (max-width: 500.67px) {
  button {
    font-size: 13.5px !important;
  }
  .label-web {
    display: none;
  }
}

/* ---inicia---- SPINNER de 'cargando....'*/
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #4c51bf; /* Color del spinner */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ---termina---- SPINNER de 'cargando....'*/

</style>
